<template>
    <div id="app" class="flex-center">
        <div class="lamp" :style="`opacity: ${opacity};`"/>
        <div class="btns-block flex-center">
            <button class="btn flex-center power-btn" @click="close()">
                <div class="btn-decoration flex-center" :style="`opacity: ${buttonsOpacity};`">
                    <img class="sml-size" src="@/assets/power-off-solid.svg"/>
                </div>
            </button>
            <button class="btn flex-center" @click="sendSetBrightness(1)">
                <div class="btn-decoration flex-center" :style="`opacity: ${buttonsOpacity};`">
                    <img v-if="btnOn === 1" class="sml-size" src="@/assets/lightbulb-solid.svg"/>
                    <img v-else class="sml-size" src="@/assets/lightbulb-regular.svg"/>
                </div>
            </button>
            <button class="btn flex-center center-btn" @click="sendSetBrightness(2)">
                <div class="btn-decoration flex-center" :style="`opacity: ${buttonsOpacity};`">
                    <img v-if="btnOn === 2" class="mid-size" src="@/assets/lightbulb-solid.svg"/>
                    <img v-else class="mid-size" src="@/assets/lightbulb-regular.svg"/>
                </div>
            </button>
            <button class="btn flex-center" @click="sendSetBrightness(3)">
                <div class="btn-decoration flex-center" :style="`opacity: ${buttonsOpacity};`">
                    <img v-if="btnOn === 3" class="big-size" src="@/assets/lightbulb-solid.svg"/>
                    <img v-else class="big-size" src="@/assets/lightbulb-regular.svg"/>
                </div>
            </button>
        </div>
        <div class="my-suggest" :style="`opacity: ${suggestOn ? 1 : 0};`">
            <span :style="`opacity: ${buttonsOpacity * 1.5};`">« {{suggest}} »</span>
        </div>
    </div>
</template>

<script>
import { createAssistant, createSmartappDebugger } from '@sberdevices/assistant-client';

const suggests = ['яркость на 2', 'потемнее', 'сделай ярче', 'выключить', 'яркость на 3', 'уменьшить свет', 'поярче', 'потемнее'];

export default {
    name: 'App',
    data() {
        return {
            assistant: null,
            brightness: 0,
            timeout: 0,
            btnOn: 0,
            suggestOn: true,
            suggestIndex: 0,
        };
    },
    computed: {
        opacity() {
            const opacities = [0, 0.3, 0.6, 1.0];
            return opacities[this.brightness];
        },
        buttonsOpacity() {
            const opacities = [0.1, 0.35, 0.2, 0.25];
            return opacities[this.brightness];
        },
        suggest() {
            if (this.suggestIndex < 0) return '';
            return suggests[this.suggestIndex];
        },
    },
    mounted() {
        const initialize = (getState) => {
            if (process.env.NODE_ENV === 'development') {
                return createSmartappDebugger({
                    token: process.env.VUE_APP_SALUTE_TOKEN,
                    initPhrase: 'запусти подсветку комнаты',
                    getState,
                });
            }

            // Только для среды production
            return createAssistant({ getState });
        };

        this.assistant = initialize(() => {});
        this.assistant.on('data', (command) => {
            if (process.env.NODE_ENV === 'development') console.log(command);

            if (command.type === 'smart_app_data') {
                if (command.action === 'close') {
                    this.assistant.close();
                }
                if (command.action === 'update_brightness') {
                    this.setBrightness(Number(command.data.value));
                }
            }
        });
        this.assistant.on('start', () => {
            const initialData = this.assistant.getInitialData();
            try {
                initialData.forEach((command) => {
                    if (command.action === 'update_brightness') {
                        this.setBrightness(Number(command.data.value));
                    }
                });
            } catch (e) {
                console.log(e);
            }
        });

        setInterval(this.setSuggest, 10000);
    },
    methods: {
        sendSetBrightness(value) {
            this.btnOn = value;
            this.assistant.sendData({ action: { action_id: 'set_brightness', payload: { value } } }, () => {});
            this.setBrightness(value, true);
        },
        setBrightness(value, slower) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.brightness = value;
                this.btnOn = this.brightness;
            }, slower ? 950 : 850);
        },
        close() {
            this.assistant.sendData({ action: { action_id: 'close', payload: { } } }, () => {});
            this.$nextTick(() => {
                this.assistant.close();
            });
        },
        setSuggest() {
            this.suggestOn = false;
            setTimeout(() => {
                if (this.suggestIndex + 1 >= suggests.length) this.suggestIndex = 0;
                else this.suggestIndex++;
                this.suggestOn = true;
            }, 600);
        },
    },
};
</script>

<style>

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

* {
    box-sizing: border-box;
    user-select: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}

button {
    padding: 0;
    border: none;
    outline: none;
}

button:focus {
    outline: none;
    box-shadow: 0 0 10px 3px #02b119c7;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: black;
    width: 100%;
    height: 100%;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.my-suggest {
    position: absolute;
    bottom: 180px;
    color: black;
    font-size: 24px;

    transition: opacity 1s ease-out;
}

.lamp {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;

    transition: opacity 1s ease-out;
}

.btns-block {
    z-index: 1;
    transition: opacity 1s ease-out;
}

.btn {
    width: 100px;
    height: 100px;
    cursor: pointer;
    background-color: transparent;
    border-radius: 50%;
}

.btn-decoration {
    border: 1px solid;
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.center-btn {
    margin: 0 20px;
}

.power-btn {
    margin-left: -100px;
    margin-right: 40px;
    width: 60px!important;
    height: 60px!important;
    opacity: 0.75;
}

.power-btn > .btn-decoration {
    width: 60px!important;
    height: 60px!important;
}

.power-btn:focus {
    box-shadow: 0 0 10px 2px rgba(177, 2, 28, 0.78);
}

.sml-size {
    width: 30px;
    height: 30px;
}

.mid-size {
    width: 38px;
    height: 38px;
}

.big-size {
    width: 50px;
    height: 50px;
}

@media (max-width: 767px) {
    .power-btn {
        margin-left: 0;
        margin-right: 0;
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .my-suggest {
        font-size: 21px;
    }
}

@media (max-width: 419px) {
    .btn {
        width: 80px;
        height: 80px;
    }

    .btn-decoration {
        width: 80px;
        height: 80px;
    }

    .my-suggest {
        font-size: 18px;
    }
}

@media (max-height: 519px) {
    .my-suggest {
        bottom: auto;
        top: 120px;
    }
}
</style>
